@use '@queries' as *;

.container {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover .check_box {
    @include min-1024-break {
      border: 2px solid var(--cst-checkbox-outline-hover);
      background: var(--cst-checkbox-background-hover);
    }
  }
  &:hover {
    .check_input:checked + .check_box {
      @include min-1024-break {
        border-color: transparent;
        background: var(--cst-checkbox-background-hover-active);
      }
    }
  }
  .check_input:checked + .check_box {
    border: 2px solid var(--cst-checkbox-background-active);
    background: var(--cst-checkbox-background-active);
  }

  .check_input:checked + .check_box:active {
    opacity: 80%;
    @include min-1024-break {
      opacity: 100%;
    }
  }
}

.check_input {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
}

.check_box {
  display: flex;
  cursor: pointer;
  width: 22px;
  height: 22px;
  align-items: center;
  justify-content: center;
  border-radius: var(--cornerradius6);
  border: 2px solid var(--cst-checkbox-outline-default);
  background: var(--cst-checkbox-background-default);
  &:active {
    border-color: var(--cst-checkbox-outline-pressed);
    @include min-1024-break {
      box-shadow: 0 0 0 2px #6941c633;
    }
  }
}

.check_input:focus-visible + .check_box {
  border: 2px solid var(--cst-checkbox-outline-focused);
}
